@import url("https://fonts.googleapis.com/css2?family=DotGothic16&family=Roboto+Condensed&display=swap");

$lapis: rgb(30, 97, 148);
$lapis_light: rgba(30, 97, 148, 0.3);
$lapis_small_light: rgba(30, 97, 148, 0.6);
$lapis_medium_light: rgba(30, 97, 148, 0.8);
$black: rgb(0, 0, 0);
$black_light: rgba(0, 0, 0, 0.5);
$ghost_white: rgb(244, 244, 249);
$ghost_white_faint: rgba(244, 244, 249, .5);
$yale_blue: rgb(23, 78, 120);
$yale_blue_light: rgba(23, 78, 120, 0.5);
$yale_blue_faint: rgba(23, 78, 120, 0.7);
$orange_web: rgb(252, 163, 17);
$orange_web_light: rgba(252, 163, 17, 0.5);

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: translateY(-4rem) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.App {
  font-family: "Roboto Condensed", sans-serif;
  position: relative;
  background-color: $ghost_white;
}

.text {
  padding: 1rem;
  font-size: 18px;
  font-family: "Roboto Condensed", sans-serif;

  p {
    line-height: 2rem;
  }
}

.scroll_up {
  position: fixed;
  z-index: 1;
  right: 0;
  text-align: right;
  bottom: 10%;
  margin: 0;
  padding: 0;
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;

  i {
    color: $ghost_white;
    background-color: $yale_blue_faint;
    padding: .75rem;
    font-size: 1.5rem;
  }

  i:hover {
    cursor: pointer;
  }
}

.newSection {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

#overview {
  background-color: $ghost_white;
  color: $black;
  border-bottom: 1px solid $yale_blue_light;
}

#keynote {
  border-bottom: 1px solid $yale_blue_light;
}

#program {
  background-color: $ghost_white;
  color: $black;
  border-bottom: 1px solid $yale_blue_light;
}

#talks {
  border-bottom: 1px solid $yale_blue_light;
}

#people {
  background-color: $ghost_white;
  color: $black;
  border-bottom: 1px solid $yale_blue_light;
}

#solvers {
  background-color: $ghost_white;
  color: $black;
  border-bottom: 1px solid $yale_blue_light;

  i {
    color: $black;
  }
}

// #papers {
//   background-color: $yale_blue;
//   color: $ghost_white;
// }

@media (min-width: 748px) {
  #overview .text {

    h2 {
      padding-bottom: 0;
    }
  }

  .text {
    padding: 0 4rem;
  }
}
