@import "../../App.scss";

h2 {
    font-weight: 600;
    margin: 0;
    padding: 0;
    padding-bottom: 1.25rem;
    font-size: 1.75rem;
}

.people_header {
    color: $black !important;
    margin: 0;
}

.profile {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.speakers {
    color: $black;
    font-size: 1.125rem;
    margin-bottom: 1rem;

    a {
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        i {
            margin: 0 .5rem;
        }
    
        i:hover {
            cursor: pointer;
        }
    }

}

.profile_image {
    width: 95%;
    height: inherit;
}

.profile_description {
    width: 85%;
    padding: 1rem 0;
    color: $black;

    h4 {
        margin-top: 1rem;
        margin-bottom: 0;
        font-size: 1.125rem;
        font-weight: 600;
        color: $orange_web;
    }

    .progfile_description p {
        font-size: .5rem;
        padding: .5rem;
    }
}

@media(min-width: 748px) {
}

@media(min-width: 1024px) {
    .profile {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .profile_image {
        width: 15rem;
        float: left;
        margin-right: 1.5rem;
    }

    .profile_description {
        width: 100%;
        padding: 1rem 0;

        h4 {
            margin-top: 0;
            font-size: 1.25rem;
        }
    }


}