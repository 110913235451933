@import "../../App.scss";

.visible--beyond-mobile {
    display: block;
}

.visible--beyond-mobile {
    display: none;
}

.title {
    text-align: center;
    font-weight: 600;
    font-size: 1.125rem;
    color: $lapis_medium_light;
}

.date_location {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    p {
        margin: .125rem 0;
        color: $black_light;
        font-weight: 600;
        width: 100%;
    }
}

.faint, .faint a {
    color: $black_light;
    padding: 0;
    margin: 0;
    font-weight: 600;
}

.program_header {
    color: $black;
    font-weight: 600;
    width: fit-content;
    margin: 0;
    margin-bottom: 0;
    padding: 0;
}

.program_link {
    color: $black;
}

// .program_tabs {
//     display: flex;
//     width: 100%;
//     justify-content: space-around;
//     margin: 0;
//     margin-top: 3rem;
//     border-bottom: .125px solid $orange_web_light;

//     p {
//         font-weight: 500;
//         font-size: 1.125rem;
//         width: 100%;
//         text-align: center;
//         padding: .25rem 0;
//         margin: 0;
//         color: $yale_blue_light;

//         span {
//             font-size: 1.25rem;
//         }
//     }

//     .active {
//         border-bottom: 2px solid $orange_web;
//         color: $yale_blue;
//     }

//     p:hover {
//         cursor: pointer;
//     }
// }

.solver_link {

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

table {
    width: 100%;
}

tr, td {
    text-align: left;  
    width: 100%;
}


.time {
    display: table-cell;
    vertical-align: top;
    width: 30%;
    border-bottom: 1px solid $black;
    border-right: 1px solid $black;
    display: none;
}

.link_cell {
    display: table-cell;
    vertical-align: top;
    width: 30%;
    border-bottom: 1px solid $black;
    border-left: 1px solid $black;
    display: none;
}

.program_description {
    display: table-cell;
    vertical-align: top;
    width: 40%;
    border-bottom: 1px solid $black;
    padding: 1rem;


    h4 {
        font-weight: 600;
        font-size: 1.125rem;
        margin: 0;

        .date {
            font-weight: 400;
        }
    }

    // a {
    //     color: $black;
    // }

    .program_details {
        // display: flex;
        // justify-content: space-between;
        // flex-wrap: wrap;
        // margin: .5rem 0;

        // span {
        //     margin-top: 0;
        // }

        // a {
        //     color: $black_light;
        // }
    }
}

.lightning_talk {
    margin: 2.5rem 0rem;

    p {
        margin: .5rem 0;
    }

    .talk_title {
        font-weight: 600;
        color: $orange_web;
        margin: 0;
        padding: 0;
    }
}

tr, td {
    padding: 1rem;
}

@media(min-width: 748px) {

    .visible--beyond-mobile {
        display: inline;
    }

    .visible--only-mobile {
        display: none;
    }

    .link_cell {
        display: table-cell;
    }

    .time {
        display: table-cell;
        width: 20%;
    }

    .date_location {
    
        p {
            width: 50%;
            text-align: right;
        }
    }
}
