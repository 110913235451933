@import "../../App.scss";
@import url('https://fonts.googleapis.com/css2?family=DotGothic16&display=swap');

.home_image {
    background-image: url("../../assets/spacexrocketreturn.jpg");
    background-size: cover;
    background-position-x: -150px;
    height: 100vh;
    margin: 0;
    padding: 0;
    position: relative;
}

.overlay_text {
    background-color: $yale_blue_light;
    color: $ghost_white;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        width: 75%;
        text-align: center;
        line-height: 4rem;
        font-weight: 900;
        font-size: 2rem;
        font-family: 'DotGothic16', sans-serif;
        text-shadow: -3px 3px 3px #000;
        border-radius: 5px;
        padding: .5rem;
        animation-name: pop-in;
        animation-duration: 2s;
    }
}

.quick_link {
    font-size: 1rem;
    color: $ghost_white;
    font-weight: 400;
    text-shadow: none;
    font-family: "Roboto Condensed", sans-serif;
    padding: .625rem 3rem;
    background-color: $yale_blue_light;
    border-radius: 2rem;
    text-decoration: none;
    box-shadow: 0 0 4px $ghost_white_faint;
    line-height: .5rem;

    &:hover {
        background-color: $yale_blue;
    }
}

#home .text {

    p {
        font-size: 18px;
        color: $ghost_white;
        margin: 0 !important;
    }
}

.text-container {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
}

@media(min-width: 748px) {
    .home_image {
        background-position-x: inherit;
        background-position: cover;
    }

}

@media(min-width: 1024px) {

    .overlay_text {

        h1 {
            width: 50%;
            font-size: 3.25rem;
            line-height: 6rem;

            div {
                line-height: 3.5rem;
            }
        }
    }

}
