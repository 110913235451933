@import "../../App.scss";

footer {
    background-color: $black;
    border-top: 2px solid $black_light;
    color: $ghost_white;
    padding: .5rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    text-align: center;
    
    p {
        text-align: center;
    }
}

@media(min-width: 748px) {
    
    footer {
        flex-direction: row;
        justify-content: space-between;
    }
}
