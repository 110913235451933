h2 {
    display: flex;
    align-items: center;
}

.fab {
    font-size: 25px;
    color: black;
    margin-left: 1rem;
}

.fab:hover {
    cursor: pointer;
}