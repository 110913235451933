@import "../../App.scss";

.clear-nav {
    position: fixed;
    top: 0;
    z-index: 2;
    background-color: $yale_blue;
    transition: background-color 1.5s;
}

.solid-nav {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
    /* scrolling colors */
    background-color: $yale_blue;
    color: $ghost_white;
    transition: background-color 1s;
  }

nav {
    box-sizing: border-box;
    font-family: 'Roboto Condensed', sans-serif;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid $yale_blue_light;

    .nav_link {
        display: none;
        text-decoration: none;
        color: $ghost_white;
        font-weight: 400;
        padding: .25rem 0;
        font-size: 17px;

        &:hover {
            color: $orange_web;
            cursor: pointer;
        }
        
        &.active {
            color: $orange_web;
        }
    }

    .logo {
        display: block;
        width: 70%;
        font-weight: 700;
        color: $ghost_white;
        text-decoration: none;

        &:hover {
            cursor: pointer;
        }

        span a {
            color: $orange_web;
            text-decoration: none;
            font-size: 1rem;
        }
    }
}

.container {
    cursor: pointer;
    border: 1px solid silver;
    border-radius: 5px;
    padding: .25rem .5rem;
}
  
.bar1, .bar2, .bar3 {
    width: 24px;
    height: 2px;
    background-color: $ghost_white;
    margin: 4px 0;
    transition: 0.4s;
}
  
.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-5px, 6px);
    transform: rotate(-45deg) translate(-5px, 5px);
}
  
.change .bar2 {opacity: 0;}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-5px, -6px);
    transform: rotate(45deg) translate(-3px, -4px);
}

.hamburgerDropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: $yale_blue;
    padding: .5rem .875rem;

    div {
        color: $ghost_white;
        text-decoration: none;
        width: 100%;
        padding: .75rem 0;
    }
}

@media( min-width: 1024px) {

    .clear-nav {
        background-color: initial;
    }

    nav {
        padding: 1rem; 
        padding-right: 3rem;

        .logo {
            width: 50%;
            font-size: 1.3125rem;
        }
    
        .nav_link {
            display: block;
        }
    }

    .container {
        display: none;
    }

}
